import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useAuthContext } from "@fitplan/context";
import Layout from "../../components/layout/layout";
import "../login/Login.scss";
import { SignUpForm } from "../../lib/components/UserAuth";
import { SignUpFooter } from "../../lib/components/UserAuth/SignUpForm";
import { navigateTo } from "../../lib/UrlUtils";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const SignUp = ({ data: { ribbon, header, footer } }) => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const { loggedIn } = authContext;
  useEffect(() => {
    if (loggedIn) {
      return navigateTo("/account");
    }
  }, [])

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <section className="login-page">
        <div className="login-form-container">
          <h1>{t("welcomeToFitplanText")}</h1>
          <SignUpForm
            onComplete={(user) => {
              navigateTo("/subscribe");
            }}
          />
          <SignUpFooter />
        </div>
      </section>
    </Layout>
  );
};

export default SignUp;
